.field--name-body,
.text-formatted {
  .field + * {
    margin-top: $global-margin / 2;
  }

  .field__item > *:last-child {
    margin-bottom: 0;
  }
}

@import "settings";

.node--type-person,
.node--type-school-person {
  .field--name-field-function {
    @include hiag-label($field-label-color, $field-label-alpha);
  }

  &.node--view-mode-hiag-card {
    @include card-container(
      $hiag-person-background-color,
      $hiag-person-font-color,
      $global-margin,
      $hiag-person-card-border,
      $global-radius,
      $hiag-person-card-shadow
    );

    .hiag-person-header.card-divider {
      background-color: $hiag-person-card-divider-background-color;
      color: $hiag-person-card-divider-font-color;
      align-items: center;
      padding: 0;

      .field--name-field-portrait {
        flex: 1;
        max-width: max-content;

        img {
          object-fit: cover;
          height: 200px;
        }
      }

      .hiag-person-title-section {
        margin: $global-margin;
        flex: 2;

        .field--name-field-function {
          padding-bottom: #{$global-padding * 0.5};
        }

        .field--name-field-deputy {
          color: $hiag-person-weak-title-color;
          font-size: smaller;

          .field__label {
            font-weight: normal;
          }
        }
      }
    }

    .hiag-person-content {
      display: flex;
      flex-direction: column;
      gap: $global-margin;

      .hiag-person-content-section {
        display: flex;
        flex-wrap: wrap;
        gap: $global-margin;

        .field--name-field-phone {
          min-width: 170px;
        }
      }

      .hiag-person-separator {
        border-top: $hiag-person-body-seperator-border;
        width: 100%;
      }

      .hiag-person-subcontent-section {
        display: flex;
        flex-wrap: wrap;
        gap: $global-margin;

        .field:not(.field--type-text-with-summary):not(
            .field--type-text-long
          ):not(.field--name-field-competence) {
          flex: 1;
          max-width: 33%;
          min-width: min-content;
        }

        .field--type-text-with-summary,
        .field--type-text-long,
        .field--name-field-competence {
          width: 100%;
        }
      }
    }
  }

  &.node--view-mode-hiag-person {
    .node__content {
      display: flex;
      flex-wrap: wrap;
      gap: $global-margin;
      padding: $global-padding 0;

      @include breakpoint(small down) {
        flex-direction: column-reverse;

        .person-contact-block {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          gap: $global-margin;
          & > * {
            flex: 1;
            min-width: 200px;
            height: min-content;
            margin: 0;
          }
        }
      }
    }

    & > h4 {
      width: 100%;
      border-bottom: $global-std-border;
    }

    .field--name-body {
      position: relative;
      overflow-y: hidden;
      transition: height map-get($transition-speed, "base");

      .button.field--name-body--expand-more-button {
        position: absolute;
        right: 0;
        bottom: 0;
        transition: transform map-get($transition-speed, "base");
      }

      &:before {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 50px;
        width: 100%;
        transition: opacity map-get($transition-speed, "base");
      }
      &.closed {
        height: 100px;
        &:before {
          content: "";

          opacity: 1;
          background: $main-content-background;
          background: -moz-linear-gradient(
            0deg,
            $main-content-background 0%,
            change-color($main-content-background, $alpha: 0) 100%
          );
          background: -webkit-linear-gradient(
            0deg,
            $main-content-background 0%,
            change-color($main-content-background, $alpha: 0) 100%
          );
          background: linear-gradient(
            0deg,
            $main-content-background 0%,
            change-color($main-content-background, $alpha: 0) 100%
          );
        }
      }

      &.expanded {
        height: calc(var(--body-inner-height) + 50px);
        &:before {
          content: "";
          opacity: 0;
        }
      }
    }

    .person-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: $global-margin;
    }

    .field--label-inline {
      display: flex;

      .field__label {
        flex: 1;
      }

      .field__items,
      & > .field__item {
        flex: $field-label-inline-flex;
      }

      @include breakpoint($field-label-inline-breakpoint down) {
        flex-direction: column;
      }
    }

    .field--name-field-function {
      transform: translateY(-#{$global-margin});
    }
  }

  &.node--view-mode-full {
    .field--name-field-function {
      transform: translateY(-#{$global-margin});
    }

    .page-sub-content {
      @include breakpoint(#{$view-mode-full-content-stacked-breakpoint} up) {
        .field--name-field-portrait {
          img {
            width: 100%;
            max-width: 250px;
          }
        }
      }
    }
  }
}
